import { FC, useEffect } from "react";
import { Column, Grid } from "@twilio-paste/grid";
import { Box } from "@twilio-paste/box";
import { useLogContext } from "../../hooks/useLogContext/useLogContext";
import { Outlet, useLocation } from "react-router-dom";

interface IntroContainerProps {
  transparentBackground?: boolean;
}

export const IntroContainer: FC<IntroContainerProps> = (props: IntroContainerProps) => {
  const location = useLocation();
  const { rum, connect, providerReady } = useLogContext();

  useEffect(() => {
    if (!rum) {
      connect();
    }

    rum?.recordPageView(location.pathname);
  }, [connect, location, rum, providerReady]);

  return (
    <Box width="100%" height="100vh" style={{ background: "#EBECF0" }}>
      <Box
        display="flex"
        flexDirection="row"
        height="fit-content"
        justifyContent="center"
        alignItems="start"
        alignContent="center"
        width="100%"
        position="relative"
        style={{ background: "#EBECF0" }}
      >
        <Box
        boxShadow="shadow"
          width={["100%", "90%", "800px"]}
          backgroundColor="colorBackgroundBody"
          borderRadius={[
            "borderRadius0",
            "borderRadius30",
            "borderRadius30",
          ]}
          height="fit-content"
          marginY={["space0", "space120", "space120"]}
        >
          <Grid vertical={true}>
            <Column span={[4, 4, 9]}>
              <Box
                padding="space50"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                alignContent="center"
                width="100%"
                height="100%"
              >
                <Outlet />
              </Box>
            </Column>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};