import { AwsRum, AwsRumConfig } from "aws-rum-web";
import { createContext, FC, useState } from "react";
import { ReactElementProps } from "../../interface";
import { LogContextType } from "../../types/ciptex-sdk";
import packageJson from "../../../package.json";

export const LogContext = createContext<LogContextType>(null!);

export const LogProvider: FC<ReactElementProps> = ({ children }: ReactElementProps) => {
	const [ rum, setRum ] = useState<AwsRum>();
	const [ providerReady, setProviderReady ] = useState<boolean>(false);

	const connect = () => {
		if(!process.env.REACT_APP_RUM_APPLICATION_ID || !process.env.REACT_APP_RUM_GUEST_ARN || !process.env.REACT_APP_RUM_IDENTITY_POOL_ID)
		{
			return;
		}

		try {
			const config: AwsRumConfig = {
				sessionSampleRate: 1,
				guestRoleArn: process.env.REACT_APP_RUM_GUEST_ARN,
				identityPoolId: process.env.REACT_APP_RUM_IDENTITY_POOL_ID,
				endpoint: "https://dataplane.rum.us-east-1.amazonaws.com",
				telemetries: ["errors", "performance", "http"],
				allowCookies: true,
				enableXRay: true
			};

			const awsRum: AwsRum = new AwsRum(
				process.env.REACT_APP_RUM_APPLICATION_ID,
				packageJson.version,
				"us-east-1",
				config
			);

			setRum(awsRum);
			setProviderReady(true);
		} catch (error) {
			// Ignore errors thrown during CloudWatch RUM web client initialization
		}
	};

	return <LogContext.Provider value={{ connect, rum, providerReady }}>{children}</LogContext.Provider>;
}