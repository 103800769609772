import * as Sentry from "@sentry/react";
import { SENTRY_ENVIRONMENT, SENTRY_REPLAY_ON_ERROR_SAMPLE_RATE, SENTRY_REPLAY_SESSION_SAMPLE_RATE, SENTRY_TRACE_SAMPLE_RATE, SENTRY_SAMPLE_RATE } from "../constants";

export function initialiseSentry() {
    console.debug(`Sentry config:`, {
        environment: SENTRY_ENVIRONMENT,
        sampleRate: SENTRY_SAMPLE_RATE,
        tracesSampleRate: SENTRY_TRACE_SAMPLE_RATE,
        replaysSessionSampleRate: SENTRY_REPLAY_SESSION_SAMPLE_RATE,
        replaysOnErrorSampleRate: SENTRY_REPLAY_ON_ERROR_SAMPLE_RATE
    });

	Sentry.init({
		dsn: "https://1686ed34f2d648b782677d6f966c05ec@o4505028829118464.ingest.sentry.io/4505085617897472",
		environment: SENTRY_ENVIRONMENT,
		integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
        sampleRate: SENTRY_SAMPLE_RATE,

		// Performance Monitoring
		tracesSampleRate: SENTRY_TRACE_SAMPLE_RATE,

		// Session Replay
		replaysSessionSampleRate: SENTRY_REPLAY_SESSION_SAMPLE_RATE,
		replaysOnErrorSampleRate: SENTRY_REPLAY_ON_ERROR_SAMPLE_RATE
	});
}
