import NotifiedWhite from "./images/customer/notified-white.png";
import Favicon from "./images/customer/notified-favicon.png";

export const HOST_LOBBY_LOGO = NotifiedWhite;
export const HOST_BACKGROUND_FAVICON = Favicon;

export const ACCOUNT_SID = process.env.REACT_APP_ACCOUNT_SID;
export const CONFERENCE_URL = process.env.REACT_APP_CONFERENCE_URL;

// Sentry config
export const SENTRY_ENVIRONMENT = process.env.REACT_APP_SENTRY_ENVIRONMENT || "development";
export const SENTRY_TRACE_SAMPLE_RATE = parseFloat(process.env.REACT_APP_SENTRY_TRACE_SAMPLE_RATE || "");
export const SENTRY_REPLAY_SESSION_SAMPLE_RATE = parseFloat(process.env.REACT_APP_SENTRY_REPLAY_SESSION_SAMPLE_RATE || "");
export const SENTRY_REPLAY_ON_ERROR_SAMPLE_RATE = parseFloat(process.env.REACT_APP_SENTRY_REPLAY_ON_ERROR_SAMPLE_RATE || "");
export const SENTRY_SAMPLE_RATE = process.env.REACT_APP_SENTRY_SAMPLE_RATE === undefined ? undefined : parseFloat(process.env.REACT_APP_SENTRY_SAMPLE_RATE);
