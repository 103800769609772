import { StrictMode } from "react";
import { render } from "react-dom";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "./components/ThemeProvider/ThemeProvider";
import { Router } from "./core/Router";
import { initialiseSentry } from "./core/sentry";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

initialiseSentry();

render(
  <StrictMode>
    <ThemeProvider>
    <I18nextProvider i18n={i18n} defaultNS={"translation"}>
      <Router />
      </I18nextProvider>
    </ThemeProvider>
  </StrictMode>,
  document.querySelector("#root"));

/**
 * If you want to start measuring performance in your app, pass a function
 * to log results (for example: reportWebVitals(console.log))
 * or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
 */
// eslint-disable-next-line no-console
reportWebVitals(console.log);
