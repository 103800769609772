export const DAYS_BEFORE_REGISTRATION_OPENS = 45;

export function isWithinRegistrationWindow(bookingStartDate: string): boolean {
  if (!bookingStartDate) {
    console.warn("isWithinRegistrationWindow: No booking date specified");
    return false;
  }

  const registrationOpeningDate = new Date(bookingStartDate);

  registrationOpeningDate.setDate(
    registrationOpeningDate.getDate() - DAYS_BEFORE_REGISTRATION_OPENS
  );

  const now = new Date();

  return now > registrationOpeningDate;
}
