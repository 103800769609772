import { NotifiedClient, Token } from "@ciptex/notified"
import { useEffect, useState } from "react";
//import { ACCOUNT_SID } from "../../constants";

const client = new NotifiedClient();
export const useToken = ({ accountSid }: { accountSid?: string }): [Token | undefined, boolean] => {
    const [result, setResult] = useState<Token>();
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        (async () => {
            try {
                if(!accountSid)
                {
                    return;
                }

                setLoading(true);
                const token: Token = await client.hostauth.authenticate(accountSid, { email: "noreply@vevent.com", password: "public" });
                setResult(token);
            } catch (error) {
                setLoading(false);
            }
        })();
    }, [accountSid]);


    return [result, loading];;
}